<template>
  <PatologiesPage
    title="Diverticolo di Zenker"
    subtitle1="Il diverticolo di Zenker è una patologia relativamente rara che è caratterizzata dalla presenza di una estroflessione sacciforme della mucosa dell’ipofaringe a livello cervicale (diverticolo), indotta dalle contrazioni del faringe contro uno sfintere esofageo superiore che non si apre oppure lo fa in maniera inadeguata durante le deglutizioni. Il paziente generalmente lamenta una sensazione di difficoltà transito del bolo alimentare (disfagia) a livello cervicale, rigurgito alimentare o salivare, a volte con aspirazione nelle vie aeree dello stesso, alitosi e più raramente calo ponderale."
    subtitle2="La diagnosi viene generalmente posta o con l’EGDS o con l’Rx tubo digerente prime vie che è opportuno vengano sempre eseguiti entrambi. In questi pazienti l’esecuzione della manometria esofagea non è obbligatoria ma è consigliabile soprattutto qualora vi sia il sospetto che possa esservi anche un’alterazione motoria primitiva dell’esofago."
    subtitle3="I trattamenti a disposizione possono essere raggruppati in due famiglie:
TRATTAMENTI ENDOSCOPICI: consistono nell’effettuare una sezione del setto (la parete esofagea che separa il lume dell’esofago da quello del diverticolo), mettendo in comunicazione esofago e diverticolo e sezionando contemporaneamente lo sfintere esofageo superiore, permettendo quindi un agevole transito del bolo alimentare. Tale sezione può essere effettuata in vari modi: attraverso un endoscopio flessibile o rigido e con l’utilizzo di differenti tipi di bisturi (elettrobisturi, ad argon-plasma, a CO2) oppure con una suturatrice meccanica lineare. Quest’ultima tecnica detta Esofago-diverticulo-stomia transorale rigida con stapler presenta il vantaggio che, oltre a sezionare il setto, ne effettua la sutura lateralmente, ed è quella preferibilmente utilizzata presso il nostro Centro.
TRATTAMENTI CHIRURGICI: sono quelli classici e consistono nell’effettuazione, attraverso una incisione laterale del collo (cervicotomia), di una sezione delle fibre muscolari dello sfintere esofageo superiore e dell’esofago cervicale (miotomia) ed, in caso di diverticoli > 2cm, nell’asportazione del diverticolo (diverticulectomia) o più raramente nella sospensione verso l’alto dello stesso (diverticulopessi).
Oggigiorno i trattamenti endoscopici sono quelli più utilizzati perché si sono rivelati efficaci nella risoluzione dei sintomi in buona parte dei pazienti e gravati da un’incidenza di complicanze minore. Tuttavia la chirurgia ha ancora un ruolo importante in caso di fallimento dei trattamento endoscopico o in caso di diverticoli troppo piccoli in cui non sia possibile una settotomia efficace.
In entrambi i casi la degenza è breve, di un paio di giorni, ed al paziente viene consentita la ripresa di una dieta morbida per le prime due settimane e quindi una dieta libera."
    cta1="Costituisce il primo momento di ricognizione e valutazione della storia clinica, dei sintomi e dello stato di salute. Il medico specialista prende visione degli esami diagnostici effettuati; per questo motivo le consigliamo di portare con sé almeno una TC del torace e dell’addome con mezzo di contrasto ed un’esofagogastroscopia recente, corredata di biopsia ed indagine istologica."
    cta2="Il medico potrebbe richiederle alcuni approfondimenti diagnostici utili alla scelta terapeutica più efficace e personalizzata. Potrebbero completare il quadro strumentale alcuni esami, fra cui lo studio radiologico delle prime vie digestive, la manometria esofagea, la pH-impedenziometria esofagea delle 24 ore e la pH-metria esofagea delle 24 ore."
    cta3="Le opportunità di trattamento possono variare da terapie mediche, mediante la sola assunzione di farmaci, a trattamenti endoscopici, fino ad intervento chirurgico con la preferenza per approcci di chirurgia mini-invasiva. La scelta migliore sarà discussa e condivisa con lei."
    cta4="Al termine della maggior parte dei trattamenti terapeutici proposti si rende utile seguire un piano di controlli diagnostici periodici, concordati con il medico specialista. Questi saranno valutati durante la visita con lo specialista e verranno suggeriti i successivi."
  />
</template>

<script>
import PatologiesPage from "../components/PatologiesPage";

export default {
  name: "Zenker",
  components: {
    PatologiesPage,
  },
};
</script>
